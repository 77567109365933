<template>
  <div class=" contailPanel">
    <div class="leftbox" >
      <leftab @changenar='selectLeftab' 
        :active="inspect" 
        :title="tabtitle" 
        :menupath="listpath"
        :list="tablist" />
    </div>
    <div class="mainbox" >
      <router-view />
    </div>
  </div>
</template>

<script>
import leftab from "../../components/all/leftab"; //引入二级菜单切换组件
// import pageList from "@/components/list/pageList"; //引入内容组件
// import newsList from "./newsList"

export default {
  name: "news", //信息公告页面
  components: {
    // pageList, //引入内容组件
    // newsList,
    leftab,
  },
  props: {
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 0,
    },
  },
  data() {
    return {
      tabtitle: "个人信息",
      tablist: [
        {
          title:  "个人信息",
          value: "info",
        }, 
        {
          title:"修改密码",
          value: "pwd",
        }
      ],
      listpath: '/user/',

      inspect: "0",
     
    }
  },
  created() {
  },
  
  mounted() {},
  methods: {

    selectLeftab(index){

      this.newsTag = index
      console.log(" this.newsTag:", this.newsTag)
    },

  },
};
</script>

<style scoped lang="less">
// @media screen and (min-width: 1000px) {
//   .detailbox {
//       min-height: 50vh;
//     // 面包屑
//     /deep/ .more {
//       display: none;
//     }
//     /deep/ .cardbox {
//       padding: 0;
//       .cardhead {
//         padding-left: 0.375rem;
//       }
//       .cardcent {
//         margin-top: 1rem;
//         padding-left: 0.375rem;

//       }
//     }
//   }
// }

// @media screen and (max-width: 999px) {
//   .detailbox {
//       min-height: 50vh;
//     // 面包屑
//     /deep/ .more {
//       display: none;
//     }
//     /deep/ .cardbox {
//       padding: 0 .9375rem;
//       .cardhead {
//         padding-left:.675rem;
//       }
//       .cardcent {
//         .hangbox {
//           height: 1.8rem;
//           margin: 0;
//         }
//       }
//     }
//   }
// }
</style>
